


import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button,Navbar, Nav, } from 'react-bootstrap';
import { auth } from '../firebase.config'; // Firebase configuration
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'; // Firebase Auth imports
import { useNavigate } from 'react-router-dom'; // For navigation
import '../CSS/signfrm.css';
import Header from './Header'; // Import the new Header component
import axios from 'axios';
import Footer from './Footer';
import logoimage from '../assets/DF-LOGO1 (2).png';
import Illustration from '../assets/Illustration.png'
import MyCards from './MyCards';
import UserHeader from './UserHeader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import yourImage from '../assets/gradientback.jpeg';


export default function SignUp() {

  const [memberIdState, setMemberIdState] = useState(''); // State to handle the member ID input

  const handleMemberIdChange = (e) => {
    const upperCaseMemberId = e.target.value.toUpperCase(); // Convert to uppercase
    setMemberIdState(upperCaseMemberId); // Update state with uppercase value
  };

  const [showForm, setShowForm] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default to India
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  // const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false); // Initial state is false
  const otpRef = useRef(null); // Ref for OTP input
  const navigate = useNavigate();

  // Checkbox states
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [communicationAccepted, setCommunicationAccepted] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const timer = setTimeout(() => {
        setHideImage(true);
        setShowForm(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6 && verificationId) {
      verifyOtp();
    }
  }, [otp]);

  useEffect(() => {
    if (phoneNumber.length === 10 && !otpSent) { // Ensure OTP is sent only once
      sendOtp();
      if (otpRef.current) {
        otpRef.current.focus(); // Focus OTP field when OTP is sent
      }
    }
  }, [phoneNumber]);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            console.log('reCAPTCHA solved', response);
          },
        },
        auth
      );
    } else {
      console.log('reCAPTCHA already initialized');
    }
  };

  const sendOtp = async () => {
    setupRecaptcha();

    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      setOtpSent(true); // Update state after sending OTP
      setError('');
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Failed to send OTP. Try again later.');
      window.recaptchaVerifier.reset(); // Reset recaptcha on failure
    }
  };

  const verifyOtp = async () => {
    if (!otp || !verificationId) {
      setError('Please enter the OTP.');
      return;
    }

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setSuccess('OTP Verified Successfully!');
      setError('');
      // Navigate to payment page or any other page on success
      // navigate('/pay');
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP. Please enter the correct OTP.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const memberId = e.target.memberId.value;
    const email = e.target.email.value;
    const dob = e.target.dob.value;
    const address = e.target.address.value;
    const phoneNumber = e.target.phoneNumber.value.replace(/\D/g, ''); // Remove non-digit characters
  
   // Check if both checkboxes are checked
   if (!termsAccepted || !communicationAccepted) {
    setError('Please accept the terms and conditions and communication preferences.');
    return; // Do not proceed if not accepted
  }
   
    try {
      const response = await axios.post('https://discountfeast.co.in/newmember/register', { name, memberId, email, dob, address, phoneNumber });
      console.log(response.data);

      
        // Display success toast message
    toast.success('Registered successfully');
      // Navigate to the /offercards page after successful registration
      navigate('/');
    } catch (err) {
      console.error('Error registering member:', err);
      setError('Failed to register member. Please try again.');
    }
  };
  

  return (
   
     <>
    <div
  style={{
    backgroundImage: `url(${yourImage})`,
    backgroundSize: 'cover',      // Makes the image cover the entire div
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundPosition: 'center',  // Centers the image within the div
    // height: '500px',               // Adjust the height as needed
  }}
>
      <Col lg={12}>
          <UserHeader/>
      </Col>
      <Container fluid className=" p-0 " style={{width:'95%'}}>
        
        <Row className="w-100" style={{ marginLeft: '0px' }}>

        <Col lg={12} md={10} sm={12} xs={12} className="pt-4 ">
    <div
      className="shadow-container"
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '2px',
      }}
    >

<Row className="w-100">
          

 <Col lg={12} md={12} sm={12} xs={12} className={`form d-flex   ${showForm ? 'show' : ''}`}>
    <div className=" w-100">
    {/* Form Section */}
    <Row>
    <Col Col lg={6} md={8} sm={8} xs={12} className='d-flex'>
    <div className="signinfrm form-container mx-auto pt-3" style={{ padding: '0 10%' }}>
                <h2 className=" ">Membership Card Verification</h2>
                <p>Membership Validity 999 Days.</p>

                <Form onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <Row className=''>
                    <Col lg={12} md={12} sm={12} xs={12}  >
                      <Form.Group controlId="name" className="mb-3">
                        {/* <Form.Label>Name</Form.Label> */}
                        <Form.Control
                         className="inputfield border-bottom-only"
                          type="text"
                          placeholder="Full Name"
                          name="name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                    <Form.Group controlId="memberId" className="mb-3">
      {/* <Form.Label>Member ID</Form.Label> */}
      <Form.Control
       className="inputfield border-bottom-only"
        type="text"
        placeholder="Card Number"
        name="memberId"
        value={memberIdState} // Use the state value
        onChange={handleMemberIdChange} // Event handler to convert to uppercase
        required
      />
    </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex '>
                    <Col lg={6} md={6} sm={4} xs={4}>
                      <Form.Group controlId="email" className="mb-2">
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control
                         className="inputfield border-bottom-only"
                          type="email"
                          placeholder="Email"
                          name="email"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={2} xs={2}>
                      <Form.Group controlId="dob" className="mb-2">
                        {/* <Form.Label>Date of Birth</Form.Label> */}
                        <Form.Control
                         className="inputfield border-bottom-only"
                        type="date"
                        placeholder="Date of Birth"
                          name="dob"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className=''>
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <Form.Group controlId="address" className="mb-3">
                        {/* <Form.Label>Address</Form.Label> */}
                        <Form.Control
                        className="inputfield border-bottom-only"
                           type="text"
                          placeholder="Address"
                          name="address"
                          required
                        />
                      </Form.Group>
                    </Col>
                    </Row>
                    <Row className='d-flex '> 
                    <Col lg={6} sm={8} md={8} xs={12}>
                      <Form.Group controlId="phoneNumber" className="mb-3">
                        {/* <Form.Label>Phone Number</Form.Label> */}
                        <div>
                          <Form.Control
                          className="inputfield border-bottom-only"
                            type="text"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            name="phoneNumber"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* OTP Section - Initially displayed and enabled/disabled based on `otpSent` state */}

                    <Col lg={6} sm={12} md={12} xs={12}>
                      <Form.Group controlId="otp" className="mb-2">
                        {/* <Form.Label>Enter OTP</Form.Label> */}
                        <Form.Control
                         className="inputfield border-bottom-only"
                          type="text"
                          placeholder="SMS OTP"
                          style={{backgroundColor:'#D9D9D9',color:'black'}}
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          maxLength={6}
                          required
                          ref={otpRef} // Attach ref to OTP input
                          disabled={!otpSent} // Disable OTP field if OTP not sent
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className=''>
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-2">
                            <Form.Check // prettier-ignore
                              type={type}
                              id={`default-${type}`}
                              label={<span>
                                Creating an account means you’re okay
                                with our <span style={{ textDecoration: 'underline' }}>Terms of Service, Privacy Policy,</span>
                                and our default <span href="" style={{ textDecoration: 'underline' }}>Notification Settings.</span>
                              </span>}
                              checked={termsAccepted}
                              onChange={(e) => setTermsAccepted(e.target.checked)}
                              className="custom-checkbox" 
                            
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-2">
                            <Form.Check // prettier-ignore
                              type={type}
                              id={`default-${type}`}
                              label={<span>
                                I would like to receive communication
                                & updates on <span href="" style={{ textDecoration: 'underline' }}>WhatsApp </span> with the number I have provided.
                              </span>}
                              checked={communicationAccepted}
                              onChange={(e) => setCommunicationAccepted(e.target.checked)}
                              className="custom-checkbox" 
                           />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>

                  <div id="recaptcha-container" className="mb-2"></div>
                  {error && <p className="text-danger text-center">{error}</p>}
                  <Row className='d-flex '>
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <Button type="submit" className="w-100" style={{backgroundColor:'#EF4C0B', color:'white', border:'none',height:'50px' }} onClick={MyCards}>
                        Register
                      </Button>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <p className='d-flex justify-center'>Already a member? <Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '0px 0px',
                borderRadius: 'none',
                color:'#0D6EFD',
                backgroundColor:'transparent',
                border:'none'
                
              }}
              onClick={() => navigate('/signupform')}
            >
             Sign in
            </Button>
                      </p>
                    </Col>
                    
                  </Row>
                </Form>
              </div>
              </Col>

              <Col Col lg={6} md={8} sm={8} xs={12} className='pt-3'>
              <h3 style={{fontWeight:'bold',paddingLeft:'20px'}}>How It Works:</h3>

              <ol className='pt-3 custom-list' >
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Verify Your Membership</h6>
    <p>Once you receive the card, visit <span style={{ textDecoration: 'underline' }}>Discount Feast Login</span>  and enter the Member ID from your card to activate your membership.</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>BLog In to Your Account .</h6>
    <p>Use your phone number and OTP to log in</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Browse and Find Deals </h6>
    <p>Search for hotels and deals near you that catch your eye.</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Redeem Offers </h6>
    <p>Use the deal you love within 24 hours at the hotel or store.</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Enjoy with Discount Feast!  </h6>
    <p>Experience more savings and fun with every purchase.</p>
  </li>
</ol>


        </Col>
        </Row>
            </div>
            </Col>

        </Row>
         </div>
         </Col>
        </Row>
      </Container>
      <Col lg={12} className='mt-4'>
      <Footer/>
      </Col>
      </div>
    </>
  );
}
