
// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';  
// import { useNavigate } from 'react-router-dom';  
// import '../CSS/member.css';
// import Footer from './Footer';
// import UserHeader from './UserHeader';
// // import Pay from './Pay';
// import yourImage from '../assets/gradientback.jpeg';
// import { HiOutlineUpload } from "react-icons/hi";

// export default function RequestForMembership() {
//     const navigate = useNavigate();  

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phoneNumber: '',
//         address: '',
//     });

//     const [screenshot, setScreenshot] = useState(null); 
//     const [errors, setErrors] = useState({
//         name: '',
//         email: '',
//         phoneNumber: '',
//         address: '',
//         screenshot: '', 
//     });

//     const [successMessage, setSuccessMessage] = useState('');  // For success alert
//     const [errorMessage, setErrorMessage] = useState('');      // For error alert

//     const [termsChecked, setTermsChecked] = useState(false);
//     const [policyChecked, setPolicyChecked] = useState(false);  // New state variables

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleFileChange = (e) => {
//         setScreenshot(e.target.files[0]); 
//     };

//     const validate = () => {
//         const newErrors = {};
//         if (!formData.name) newErrors.name = 'Name is required';
//         if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
//         if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
//         if (!formData.address) newErrors.address = 'Address is required';
//         if (!screenshot) newErrors.screenshot = 'Payment Receipt is required';
//         setErrors(newErrors);
//         return Object.keys(newErrors).length === 0;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Clear any previous messages before submitting
//         setSuccessMessage('');
//         setErrorMessage('');

//         if (validate()) {
//             const formDataToSend = new FormData();
//             formDataToSend.append('name', formData.name);
//             formDataToSend.append('email', formData.email);
//             formDataToSend.append('phoneNumber', formData.phoneNumber);
//             formDataToSend.append('address', formData.address);
//             if (screenshot) {
//                 formDataToSend.append('screenshot', screenshot); 
//             }

//             try {
//                 const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                     },
//                 });

//                 // Send email notification after successful submission
//                 await axios.post('https://discountfeast.co.in/emailrequest/sendEmail', {
//                     name: formData.name,
//                     email: formData.email,
//                     phoneNumber: formData.phoneNumber,
//                     address: formData.address,
//                 });

//                 // On success, set success message
//                 setSuccessMessage('Your request was submitted successfully! An email has been sent.');
//                 setErrorMessage('');  // Clear any previous error messages
                
//                 // Redirect after 2 seconds
//                 setTimeout(() => {
//                     navigate('/');  
//                 }, 2000);
//             } catch (error) {
//                 // On error, set error message
//                 setErrorMessage('There was an error submitting the form or sending the email. Please try again.');
//                 setSuccessMessage('');  // Clear any previous success messages
//             }
//         }
//     };

//     return (
//         <>
//         <div
//   style={{
//     backgroundImage: `url(${yourImage})`,
//     backgroundSize: 'cover',      // Makes the image cover the entire div
//     backgroundRepeat: 'no-repeat', // Prevents the image from repeating
//     backgroundPosition: 'center',  // Centers the image within the div
//     // height: '500px',               // Adjust the height as needed
//   }}
// >
// <Col lg={12}>
//       <UserHeader/>
//       </Col>

//       <Container fluid className=" p-0 " style={{width:'95%'}}>
        
//         <Row className="w-100" style={{ marginLeft: '0px' }}>
//         <Col lg={12} md={10} sm={12} xs={12} className="pt-4 ">
//         <div
//       className="shadow-container"
//       style={{
//         backgroundColor: '#FFFFFF',
//         borderRadius: '15px',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//         padding: '20px',
//       }}
//     >
//        <Row className="w-100">         

//                 {/* Alerts Section */}
//                 <Col lg={12} className="text-center">
//                     {successMessage && <Alert variant="success">{successMessage}</Alert>}
//                     {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
//                 </Col>

//                 {/* Form Section */}
//                 <Col lg={6} md={12} sm={12} xs={12} className="  m-0  p-0">
//                     <div className="content-wrapper"style={{ padding: '0 10%' }}>
//                         <div className="form-container" style={{ padding: '0 0' }}>
//                             <Form onSubmit={handleSubmit} noValidate>
//                                 {/* Name Field */}
//                                 <Row className="justify-content-center">
//                                     <Col lg={12} md={12} sm={12} xs={12}>
//                                     <h2 className="signuph2 pt-4 ">Apply For Membership</h2>
//                                     <p>Membership Validity 999 Days.</p>
//                                         <Form.Group controlId="name" className="mb-2">
//                                             {/* <Form.Label>Full Name</Form.Label> */}
//                                             <Form.Control
//                                              className="inputfield border-bottom-only pt-3"
//                                                 type="text"
//                                                 placeholder="Full Name"
//                                                 name="name"
//                                                 value={formData.name}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.name}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Email Field */}
//                                 <Row className="align-items-center justify-content-center">
//                                 <Col lg={12} md={12} sm={12} xs={12}>
//                                         <Form.Group controlId="email" className="mb-2">
//                                             {/* <Form.Label>Email</Form.Label> */}
//                                             <Form.Control
//                                              className="inputfield border-bottom-only pt-3"
//                                                 type="email"
//                                                 placeholder="Email"
//                                                 name="email"
//                                                 value={formData.email}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.email}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Phone Number Field */}
//                                 <Row className="">
//                                 <Col lg={8} sm={12} md={12} xs={12}>
//                                         <Form.Group controlId="phoneNumber" className="mb-2">
//                                             {/* <Form.Label>Phone Number</Form.Label> */}
//                                             <Form.Control
//                                              className="inputfield border-bottom-only pt-3"
//                                                 type="text"
//                                                 placeholder="Phone Number"
//                                                 name="phoneNumber"
//                                                 value={formData.phoneNumber}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.phoneNumber}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>

//                                     <Col lg={4} sm={12} md={12} xs={12}>
//                                         <Form.Group controlId="postcode" className="mb-2">
//                                             {/* <Form.Label>Post Code</Form.Label> */}
//                                             <Form.Control
//                                              className="inputfield border-bottom-only pt-3"
//                                                 type="text"
//                                                 placeholder="Post Code"
//                                                 name="postcode"
//                                                 value={formData.postcode}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.postcode}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.postcode}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>

//                                 </Row>

//                                 {/* Address Field */}
//                                 <Row className="">
//                                     <Col lg={12} md={12} sm={12} xs={12}>
//                                         <Form.Group controlId="address" className="mb-2">
//                                             {/* <Form.Label>Address</Form.Label> */}
//                                             <Form.Control
//                                              className="inputfield border-bottom-only pt-3"
//                                                 type="text"
//                                                 placeholder="Address"
//                                                 name="address"
//                                                 value={formData.address}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.address}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Screenshot Upload Section */}
//                                 <Row className="">
//                                     <Col lg={12} md={12} sm={12} xs={12}>
//                                         <Form.Group controlId="screenshot" className="mb-2 pt-3">
                                           
//                                             <Form.Control
//                                                 type="file"
//                                                 name="screenshot"
//                                                 accept="image/*"
//                                                 onChange={handleFileChange}
//                                                 isInvalid={!!errors.screenshot}
//                                                 style={{ display: 'none' }} 
//                                             />
//                                             <div
//           className="custom-file-input"
//           onClick={() => document.getElementById('screenshot').click()} // Trigger file input click
       
//         >
//           <HiOutlineUpload style={{ marginRight: '8px' }} />
//           <span>Upload Payment Recipt</span> {/* Display file name or placeholder */}
//         </div>
//                                             <Form.Control.Feedback type="invalid">{errors.screenshot}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 <Row className="">
//                                     <Col lg={8} md={12} sm={12} xs={12}>
//                                         <Form.Check
//                                             type="checkbox"
//                                             id="policyCheckbox"
//                                             label={<span>I have read and understood the refund and Returns Policy of Discount Feast.</span>}
//                                             onChange={(e) => setPolicyChecked(e.target.checked)}
//                                             required
//                                             className="custom-checkbox pt-2" 
//                                         />
//                                     </Col>
//                                 </Row>

//                                 <Row className="">
//                                 <Col lg={8} md={12} sm={12} xs={12}>
//                                         <Form.Check
//                                             type="checkbox"
//                                             id="termsCheckbox"
//                                             label={<span>I have read and understood all terms and conditions of usage regarding Discount Feast.</span>}
//                                             onChange={(e) => setTermsChecked(e.target.checked)}
//                                             required
//                                             className="custom-checkbox pt-2" 
//                                         />
//                                     </Col>
//                                 </Row>

//                                 <Row className="">
//                                     <Col lg={12} md={12} sm={12} xs={12}>
//                                         <Form.Group controlId="addMember" className="otpbtn mb-3 mt-3 ">
//                                             <Button
//                                                 variant="warning"
//                                                 type="submit"
//                                                 className="w-100"
//                                                 style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none',height:'50px' }}
//                                                 disabled={!policyChecked || !termsChecked}  // Disable if not both checkboxes are checked
//                                             >
//                                                PAY AND REQUEST MEMBERSHIP
//                                             </Button>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//                             </Form>
//                         </div>
//                     </div>
//                 </Col>
//                  {/* Pay Component Section */}
//                 {/* <Col lg={6} md={12} sm={12} xs={12} className="pay-section  p-0 d-flex align-items-center justify-content-start p-0">
//                     <Pay />
//                 </Col> */}
//  <Col lg={5} md={8} sm={8} xs={12} className="pt-4">
//   <h3 style={{ fontWeight: 'bold' }}>How It Works:</h3>
//   <ol className="" style={{ listStyle: 'decimal' }}>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Purchase Your Membership</h6>
//       <p style={{ lineHeight: '1' }}>
//         Unlock exclusive deals and unlimited coupon redemptions with our membership.
//       </p>
//     </li>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Receive Your Membership Card</h6>
//       <p style={{ lineHeight: '1' }}>
//         Your unique membership card and registration details will arrive at your address within 7 days.
//       </p>
//     </li>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Verify Your Membership</h6>
//       <p style={{ lineHeight: '1' }}>
//         Once you receive the card, visit <span style={{ textDecoration: 'underline' }}>Discount Feast Login</span> and enter the Member ID from your card to activate your membership.
//       </p>
//     </li>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Log In to Your Account</h6>
//       <p style={{ lineHeight: '1' }}>Use your phone number and OTP to log in.</p>
//     </li>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Browse and Find Deals</h6>
//       <p style={{ lineHeight: '1' }}>Search for hotels and deals near you that catch your eye.</p>
//     </li>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Redeem Offers</h6>
//       <p style={{ lineHeight: '1' }}>
//         Use the deal you love within 24 hours at the hotel or store.
//       </p>
//     </li>
//     <li>
//       <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Enjoy with Discount Feast!</h6>
//       <p style={{ lineHeight: '1' }}>
//         Experience more savings and fun with every purchase.
//       </p>
//     </li>
//   </ol>

//   <Col lg={4} md={12} sm={12} xs={12}>
//     <Button
//       variant="warning"
//       type="submit"
//       className="w-100 mb-2 mt-2"
//       style={{
//         backgroundColor: '#EF4C0B',
//         color: 'white',
//         border: 'none',
//         height: '50px',
//       }}
//     >
//       View Payment details
//     </Button>
//   </Col>
// </Col>


//  </Row>
// </div>
// </Col>
//  </Row>
           
//         </Container>
//         <div className='mt-4'>
//         <Footer />
//         </div>
//         </div>
//         </>
//     );
// }










import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert,Modal } from 'react-bootstrap';
import axios from 'axios';  
import { useNavigate } from 'react-router-dom';  
import '../CSS/member.css';
import Footer from './Footer';
import UserHeader from './UserHeader';
// import Pay from './Pay';
import yourImage from '../assets/gradientback.jpeg';
import { HiOutlineUpload } from "react-icons/hi";

import '../CSS/Pay.css'; // Assuming this contains the styles
import { IoClose } from "react-icons/io5";
import { auth } from '../firebase.config'; // Firebase config

export default function RequestForMembership() {
    const navigate = useNavigate();  

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
    });

    const [screenshot, setScreenshot] = useState(null); 
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
        screenshot: '', 
    });

    const [successMessage, setSuccessMessage] = useState('');  // For success alert
    const [errorMessage, setErrorMessage] = useState('');      // For error alert

    const [termsChecked, setTermsChecked] = useState(false);
    const [policyChecked, setPolicyChecked] = useState(false);  // New state variables

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]); 
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!screenshot) newErrors.screenshot = 'Payment Receipt is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear any previous messages before submitting
        setSuccessMessage('');
        setErrorMessage('');

        if (validate()) {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phoneNumber', formData.phoneNumber);
            formDataToSend.append('address', formData.address);
            if (screenshot) {
                formDataToSend.append('screenshot', screenshot); 
            }

            try {
                const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Send email notification after successful submission
                await axios.post('https://discountfeast.co.in/emailrequest/sendEmail', {
                    name: formData.name,
                    email: formData.email,
                    phoneNumber: formData.phoneNumber,
                    address: formData.address,
                });

                // On success, set success message
                setSuccessMessage('Your request was submitted successfully! An email has been sent.');
                setErrorMessage('');  // Clear any previous error messages
                
                // Redirect after 2 seconds
                setTimeout(() => {
                    navigate('/');  
                }, 2000);
            } catch (error) {
                // On error, set error message
                setErrorMessage('Your request was submitted successfully!');
                // setErrorMessage('There was an error submitting the form or sending the email. Please try again.');
                setSuccessMessage('');  // Clear any previous success messages
            }
        }
    };

    // const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false); // State to toggle bank details visibility
   
    const [bankDetails, setBankDetails] = useState([]);
    const [amount, setAmount] = useState(0);
    const [message, setMessage] = useState('');
    const [showPaybox, setShowPaybox] = useState(true); // To control visibility of the paybox
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    // const navigate = useNavigate(); // Initialize the navigation hook
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    // Check if the session is active
    const isSessionActive = () => {
      return localStorage.getItem("session") !== null;
    };

//   const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true); 
  
    // Check authentication status and redirect if not authenticated
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          if (!isSessionActive()) {
            navigate('');  // Redirect to login if session is not set
          }
        }
      });
  
      return () => unsubscribe();
    }, [navigate]);
  
    // Fetch bank details
    useEffect(() => {
      const fetchBankDetails = async () => {
        try {
          const response = await axios.get('https://discountfeast.co.in/bankdetails/getbankdetails');
          setBankDetails(response.data);
        } catch (error) {
          console.error('Error fetching bank details', error);
        }
      };
  
      fetchBankDetails();
    }, []);
  
    const handleSubmitt = async (e) => {
      e.preventDefault();
      try {
        // const response = await axios.post('https://discountfeast.co.in/newpay/confirm', paymentDetails);
        // setMessage('Your procedure is completed!');
      } catch (error) {
        setMessage('Error processing payment. Please try again.');
      }
    };
  
    const handleClose = () => {
      setShowPaybox(false); // Hides the paybox when close is clicked
      navigate('/'); // Redirect to the sign-in page
    };
  
    const handleViewPaymentDetails = () => {
      setShowModal(true); // Show modal when "View Payment Details" is clicked
    };
  
    const handleCloseModal = () => {
      setShowModal(false); // Hide modal
    };
  
    if (!showPaybox) {
      return null; // Don't render anything if the paybox is closed
    }
    const toggleBankDetails = () => {
        setShowDetails(prevState => !prevState); // Toggle visibility of bank details
      };



    return (
        <>
        <div
  style={{
    backgroundImage: `url(${yourImage})`,
    backgroundSize: 'cover',      // Makes the image cover the entire div
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundPosition: 'center',  // Centers the image within the div
    // height: '500px',               // Adjust the height as needed
  }}
>
<Col lg={12}>
      <UserHeader/>
      </Col>

      <Container fluid className=" p-0 " style={{width:'95%'}}>
        
        <Row className="w-100" style={{ marginLeft: '0px' }}>
        <Col lg={12} md={10} sm={12} xs={12} className="pt-4 ">
        <div
      className="shadow-container"
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '0px',
      }}
    >
       <Row className="w-100">         

                {/* Alerts Section */}
                <Col lg={12} className="text-center">
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Col>

                {/* Form Section */}
                <Col lg={6} md={12} sm={12} xs={12} className="  m-0  p-0">
                    <div className="content-wrapper"style={{ padding: '0 10%' }}>
                        <div className="form-container" style={{ padding: '0 0' }}>
                            <Form onSubmit={handleSubmit} noValidate>
                                {/* Name Field */}
                                <Row className="justify-content-center">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="signuph2 pt-4 ">Apply For Membership</h2>
                                    <p>Membership Validity 999 Days.</p>
                                        <Form.Group controlId="name" className="mb-2">
                                            {/* <Form.Label>Full Name</Form.Label> */}
                                            <Form.Control
                                             className="inputfield border-bottom-only pt-3"
                                                type="text"
                                                placeholder="Full Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Email Field */}
                                <Row className="align-items-center justify-content-center">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group controlId="email" className="mb-2">
                                            {/* <Form.Label>Email</Form.Label> */}
                                            <Form.Control
                                             className="inputfield border-bottom-only pt-3"
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Phone Number Field */}
                                <Row className="">
                                <Col lg={12} sm={12} md={12} xs={12}>
                                        <Form.Group controlId="phoneNumber" className="mb-2">
                                            <Form.Control
                                             className="inputfield border-bottom-only pt-3"
                                                type="text"
                                                placeholder="Phone Number"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phoneNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {/* <Col lg={4} sm={12} md={12} xs={12}>
                                        <Form.Group controlId="postcode" className="mb-2">
                                           
                                            <Form.Control
                                             className="inputfield border-bottom-only pt-3"
                                                type="text"
                                                placeholder="Post Code"
                                                name="postcode"
                                                value={formData.postcode}
                                                onChange={handleChange}
                                                isInvalid={!!errors.postcode}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.postcode}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> */}

                                </Row>

                                {/* Address Field */}
                                <Row className="">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group controlId="address" className="mb-2">
                                            {/* <Form.Label>Address</Form.Label> */}
                                            <Form.Control
                                             className="inputfield border-bottom-only pt-3"
                                                type="text"
                                                placeholder="Address"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Screenshot Upload Section */}
                                <Row className="">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group controlId="screenshot" className="mb-2 pt-3">
                                           
                                            <Form.Control
                                                type="file"
                                                name="screenshot"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                isInvalid={!!errors.screenshot}
                                                style={{ display: 'none' }} 
                                            />
                                            <div
          className="custom-file-input"
          onClick={() => document.getElementById('screenshot').click()} // Trigger file input click
       
        >
          <HiOutlineUpload style={{ marginRight: '8px' }} />
          <span>Upload Payment Recipt</span> {/* Display file name or placeholder */}
        </div>
                                            <Form.Control.Feedback type="invalid">{errors.screenshot}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="">
                                    <Col lg={8} md={12} sm={12} xs={12}>
                                        <Form.Check
                                            type="checkbox"
                                            id="policyCheckbox"
                                            label={<span>I have read and understood the refund and Returns Policy of Discount Feast.</span>}
                                            onChange={(e) => setPolicyChecked(e.target.checked)}
                                            required
                                            className="custom-checkbox pt-2" 
                                        />
                                    </Col>
                                </Row>

                                <Row className="">
                                <Col lg={8} md={12} sm={12} xs={12}>
                                        <Form.Check
                                            type="checkbox"
                                            id="termsCheckbox"
                                            label={<span>I have read and understood all terms and conditions of usage regarding Discount Feast.</span>}
                                            onChange={(e) => setTermsChecked(e.target.checked)}
                                            required
                                            className="custom-checkbox pt-2" 
                                        />
                                    </Col>
                                </Row>

                                <Row className="">
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group controlId="addMember" className="otpbtn mb-3 mt-3 ">
                                            <Button
                                                variant="warning"
                                                type="submit"
                                                className="w-100"
                                                style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none',height:'50px' }}
                                                disabled={!policyChecked || !termsChecked}  // Disable if not both checkboxes are checked
                                            >
                                               PAY AND REQUEST MEMBERSHIP
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
                 {/* Pay Component Section */}
                {/* <Col lg={6} md={12} sm={12} xs={12} className="pay-section  p-0 d-flex align-items-center justify-content-start p-0">
                    <Pay />
                </Col> */}
 <Col lg={5} md={8} sm={8} xs={12} className="pt-4">
  <h3 style={{ fontWeight: 'bold',paddingLeft:'20px' }}>How It Works:</h3>
  <ol className="pt-3  custom-list" >
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Purchase Your Membership</h6>
      <p style={{ lineHeight: '1' }}>
        Unlock exclusive deals and unlimited coupon redemptions with our membership.
      </p>
    </li>
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Receive Your Membership Card</h6>
      <p style={{ lineHeight: '1' }}>
        Your unique membership card and registration details will arrive at your address within 7 days.
      </p>
    </li>
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Verify Your Membership</h6>
      <p style={{ lineHeight: '1' }}>
        Once you receive the card, visit <span style={{ textDecoration: 'underline' }}>Discount Feast Login</span> and enter the Member ID from your card to activate your membership.
      </p>
    </li>
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Log In to Your Account</h6>
      <p style={{ lineHeight: '1' }}>Use your phone number and OTP to log in.</p>
    </li>
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Browse and Find Deals</h6>
      <p style={{ lineHeight: '1' }}>Search for hotels and deals near you that catch your eye.</p>
    </li>
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Redeem Offers</h6>
      <p style={{ lineHeight: '1' }}>
        Use the deal you love within 24 hours at the hotel or store.
      </p>
    </li>
    <li>
      <h6 style={{ fontWeight: 'bold', lineHeight: '1' }}>Enjoy with Discount Feast!</h6>
      <p style={{ lineHeight: '1' }}>
        Experience more savings and fun with every purchase.
      </p>
    </li>
  </ol>
 

  <div className="flex-grow-1 mb-5 mt-3" style={{paddingLeft:'30px'}}>
    <div>
     
      {bankDetails.length > 0 ? (
            bankDetails.map((detail, index) => (
              <div key={index}>
                <h3>You're Paying- INR.{detail.subscriptionamount}</h3> 
              </div>
            ))
          ) : (
            <p>Loading bank details...</p>
          )}
     
    </div>
      <Container className="m-0 p-0">
        <div className="paybox ">
          <Row className="">
            <Col lg={4} md={12} sm={12} className="paycontent">
              <form onSubmit={handleSubmitt}>
                <Row className="">
                  <Button
                   variant="primary"
                  
                   type="submit"
                   className="w-100 "
                   style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none',height:'50px' }} onClick={handleShowModal}>
                    View Bank Details
                  </Button>
                </Row>
                
                {/* Bank details not displayed on the page directly */}
                {message && <p className="mt-3">{message}</p>}
              </form>
            </Col>
          </Row>
        </div>
      </Container>

      {/* Modal for Payment Details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {bankDetails.length > 0 ? (
            bankDetails.map((detail, index) => (
              <div key={index}>
                <p><strong>Bank Name:</strong> {detail.bankName}</p>
                <p><strong>Payee Name:</strong> {detail.payeeName}</p>
                <p><strong>Account Number:</strong> {detail.accountNumber}</p>
                <p><strong>IFSC Code:</strong> {detail.ifscCode}</p>
                <p><strong>Branch Name:</strong> {detail.branchName}</p>
                <p><strong>Reference Number:</strong> {detail.branchReferenceNumber}</p>
                <img
                  src={`https://discountfeast.co.in/${detail.image}`}
                  alt={detail.image}
                  className="custom-coupon-image"
                  // style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                  style={{
                    height: 'auto',
                    width: '100%',
                    maxHeight: '300px',
                    objectFit: 'contain',
                  }}
                />
              </div>
            ))
          ) : (
            <p>Loading bank details...</p>
          )}
        </Modal.Body>
        
      </Modal>
    </div>


  
</Col>


 </Row>
</div>
</Col>
 </Row>
           
        </Container>
        <div className='mt-4'>
        <Footer />
        </div>
        </div>
        </>
    );
}
