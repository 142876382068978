

// import React, { useState, useRef, useEffect } from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import { auth } from '../firebase.config';
// import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
// import axios from 'axios';
// // import '../CSS/SignUp.css';
// import '../CSS/signfrm.css'
// import { useNavigate } from 'react-router-dom';
// import logoimage from '../assets/Background Removed - Discount Feast Logo.png';
// // import { useNavigate } from 'react-router-dom';
// import Footer from './Footer';
// import Header from './Header';
// import UserHeader from './UserHeader';
// import yourImage from '../assets/gradientback.jpeg';

// export default function SignUpform() {
//   const [memberDetails, setMemberDetails] = useState({});
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [verificationId, setVerificationId] = useState(null);
//   const [otpSent, setOtpSent] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [loading, setLoading] = useState(false);
//   const recaptchaVerifierRef = useRef(null);
//   const navigate = useNavigate();
//   const otpInputRef = useRef(null);

//   // Handle sending OTP after checking the phone number in the database
//   const sendOtp = async () => {
//     setLoading(true);
//     setSuccess('');
//     setError('');

//     try {
//       const response = await axios.post('https://discountfeast.co.in/newmember/checkPhoneNumber', { phoneNumber });

//       if (response.status === 200 && response.data.exists) {
//         if (!recaptchaVerifierRef.current) {
//           recaptchaVerifierRef.current = new RecaptchaVerifier(
//             'recaptcha-container',
//             {
//               size: 'invisible',
//               callback: (response) => console.log('reCAPTCHA solved', response),
//               'expired-callback': () => console.log('reCAPTCHA expired'),
//             },
//             auth
//           );
//         }

//         const appVerifier = recaptchaVerifierRef.current;
//         const fullPhoneNumber = `+1${phoneNumber}`;
//         const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
//         setVerificationId(confirmationResult.verificationId);
//         setOtpSent(true);
//         setError('');
//         setLoading(false);

//         if (otpInputRef.current) {
//           otpInputRef.current.focus();
//         }
//       } else {
//         setError('Phone number not found. Please register.');
//         setLoading(false);
//       }
//     } catch (err) {
//       console.error('Error sending OTP:', err);
//       setError('Failed to send OTP. Please try again.');
//       setLoading(false);
//     }
//   };

//   // Verify OTP
//   const verifyOtp = async () => {
//     if (!otp || !verificationId) {
//       setError('Please enter the OTP.');
//       return;
//     }

//     setLoading(true);

//     try {
//       const credential = PhoneAuthProvider.credential(verificationId, otp);
//       await signInWithCredential(auth, credential);
//       setSuccess('OTP Verified Successfully!');
//       setError('');

//       await verifyPhoneNumberWithBackend();
//     } catch (err) {
//       console.error('Error verifying OTP:', err);
//       setError('Invalid OTP. Please enter the correct OTP.');
//       setLoading(false);
//     }
//   };

//   const verifyPhoneNumberWithBackend = async () => {
//     try {
//       // Verify phone number with backend
//       const verifyResponse = await axios.post('https://discountfeast.co.in/newmember/verifylogin', { phoneNumber });
      
//       if (verifyResponse.status === 200) {
//         setSuccess('Phone number verified successfully!');
//         localStorage.setItem('userPhoneNumber', phoneNumber); // Store phone number in local storage
  
//         // Fetch user data from backend
//         const userResponse = await axios.get(`https://discountfeast.co.in/newmember/all/${phoneNumber}`);
  
//         if (userResponse.status === 200) {
//           const userData = userResponse.data;
  
//           // Optionally store user data in local storage or in the state
//           localStorage.setItem('userData', JSON.stringify(userData)); // Save user data to localStorage
//           console.log('User Data:', userData); // You can handle user data further in your app
  
//           // Navigate to the home or user profile page
//           navigate('/');
//         } else {
//           setError('Failed to fetch user data.');
//         }
//       } else {
//         setError('Phone number not verified or not found.');
//       }
//     } catch (err) {
//       setError('Error verifying phone number: ' + (err.response?.data?.message || err.message));
//     }
//   };
  

//   useEffect(() => {
//     if (phoneNumber.length === 10 && !otpSent) {
//       sendOtp();
//     }
//   }, [phoneNumber]);

//   useEffect(() => {
//     if (otp.length === 6 && verificationId) {
//       verifyOtp();
//     }
//   }, [otp]);

//     // const navigate = useNavigate();
  
//     useEffect(() => {
//       const unsubscribe = auth.onAuthStateChanged((user) => {
//         if (user) {
//           // User is signed in, navigate to the dashboard
//           navigate('/'); // Adjust the route as needed
//         } else {
//           // No user is signed in, navigate to the login page
//           navigate('/signupform');
//         }
//       });
  
//       // Cleanup subscription on unmount
//       return () => unsubscribe();
//     }, [navigate]);

//   return (
//     <>
//     <div
//   style={{
//     backgroundImage: `url(${yourImage})`,
//     backgroundSize: 'cover',      // Makes the image cover the entire div
//     backgroundRepeat: 'no-repeat', // Prevents the image from repeating
//     backgroundPosition: 'center',  // Centers the image within the div
//     // height: '500px',               // Adjust the height as needed
//   }}
// >
//       <Col lg={12}>
//       <UserHeader/>
//       </Col> 
//       <Container fluid className=" p-0 " style={{width:'95%'}}>
        
//         <Row className="w-100" style={{ marginLeft: '0px' }}>

//         <Col lg={12} md={10} sm={12} xs={12} className="pt-4 ">
//     <div
//       className="shadow-container"
//       style={{
//         backgroundColor: '#FFFFFF',
//         borderRadius: '15px',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//         padding: '20px',
//       }}
//     >

// <Row className="w-100">
//           <Col lg={6} md={12} sm={12} xs={12} className="form1 d-flex   pt-1">
//             <div className="content-wrapper w-100">
//               <div className="signinfrm form-container mx-auto" style={{ padding: '0 10%' }}>
//                 <h2  >Member Login,</h2>
//                 <p>Membership Validity 999 Days.</p>

//                 <Form className="d-grid ">
//                   <Row className="">
//                     <Col lg={12} sm={12} md={12} xs={12}>
//                       <Form.Group controlId="PhoneNumber" className="mb-3 mt-2">
//                         {/* <Form.Label className="">Phone Number</Form.Label> */}
//                         <div className="d-flex">
//                           <Form.Control
//                            className="inputfield border-bottom-only"
//                             type="text"
//                             placeholder="Enter Phone Number"
//                             style={{backgroundColor:'transparent'}}
//                             value={phoneNumber}
//                             onChange={(e) => {
//                               setPhoneNumber(e.target.value);
//                               setMemberDetails({ ...memberDetails, phoneNumber: e.target.value });
//                             }}
//                             required
//                           />
//                         </div>
//                       </Form.Group>
//                     </Col>
//                   </Row>
//                   <Row>
//                   <Col lg={6} md={12} sm={12} xs={12} className="d-flex ">
//                   <Button
//   variant="warning"
//   style={{
//     width: '100%',
//     backgroundColor: '#EF4C0B',
//     color: 'white',
//     border: 'none',
//   }}
//   onClick={sendOtp} // Add this to trigger the sendOtp function
//   disabled={loading} // Disable button while loading
// >
//   {loading ? 'Sending...' : 'Generate OTP'}
// </Button>
//                     </Col>
//                   </Row>

//                   <Row >
//                     <Col lg={6} sm={12} md={12} xs={12} >
//                       <Form.Group controlId="otp" className="mb-3 mt-3">
//                         {/* <Form.Label className="forminput">OTP</Form.Label> */}
//                         <Form.Control
//                          className="smsotp"
//                           style={{textAlign:'center',backgroundColor:'#D9D9D9'}}
//                           type="text"
//                           placeholder="Enter SMS OTP"
//                           value={otp}
//                           onChange={(e) => setOtp(e.target.value)}
//                           required
//                           ref={otpInputRef}
//                         />
//                       </Form.Group>
//                     </Col>
//                   </Row>

//                   {error && (
//                     <div className="text-center mt-2">
//                       <p className="text-danger">{error}</p>
//                     </div>
//                   )}
//                   {success && (
//                     <div className="text-center mt-2">
//                       <p className="text-success">{success}</p>
//                     </div>
//                   )}

//                   <Row  className="" style={{ padding: '0 ' }}>
//                     <Col lg={12} md={12} sm={12} xs={12} className="d-flex ">
//                       <Button
//                         variant="warning"
//                         style={{ width: '100%', backgroundColor: '#EF4C0B', color: 'white', border: 'none',height:'50px' }}
//                         onClick={verifyOtp}
//                         disabled={loading}
//                       >
//                         {loading ? 'Processing...' : 'Login'}
//                       </Button>
//                     </Col>
//                   </Row>

//                   <Row>
                    
//                     <Col lg={12} md={12} sm={12} xs={12} className=" mt-2">
//                     Not a member yet?<Button
//               variant="secondary"
//               className="mx-2"
//               style={{
//                 fontSize: '14px',
//                 padding: '0px 0px',
//                 borderRadius: 'none',
//                 color:'#0040FF',
//                 backgroundColor:'transparent',
//                 border:'none'
                
//               }}
//               onClick={() => navigate('/requestmembership')}
//             >
//              Become a member
//             </Button>
                      
//                     </Col>
//                   </Row>

//                   <Col lg={12} className="mt-2 text-center">
//                     <small>
//                       {/* This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. */}
//                     </small>
//                     <div id="recaptcha-container"></div>
//                   </Col>
//                 </Form>
//               </div>
//             </div>
//           </Col>

//           <Col lg={6} md={12} sm={12} xs={12} className="form1    pt-1">
          
//         <h3 style={{fontWeight:'bold',paddingLeft:'20px'}}>How It Works:</h3>

//         <ol  className="pt-3 custom-list">
//   <li>
//     <h6 style={{ fontWeight: 'bold' }}>Log In to Your Account</h6>
//     <p>Use your phone number and OTP to log in.</p>
//   </li>
//   <li>
//     <h6 style={{ fontWeight: 'bold' }}>Browse and Find Deals</h6>
//     <p>Search for hotels and deals near you that catch your eye.</p>
//   </li>
//   <li>
//     <h6 style={{ fontWeight: 'bold' }}>Redeem Offers</h6>
//     <p>Use the deal you love within 24 hours at the hotel or store.</p>
//   </li>
//   <li>
//     <h6 style={{ fontWeight: 'bold' }}>Enjoy with Discount Feast!</h6>
//     <p>Experience more savings and fun with every purchase.</p>
//   </li>
// </ol>



//          </Col>
//          </Row>
//          </div>
//          </Col>
//         </Row>
//       </Container>
//       <Row className='mt-5'>
//       <Footer />
//       </Row>
     
//       </div>
//     </>
//   );
// }






import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth } from '../firebase.config';
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import axios from 'axios';
// import '../CSS/SignUp.css';
import '../CSS/signfrm.css'
import { useNavigate } from 'react-router-dom';
import logoimage from '../assets/Background Removed - Discount Feast Logo.png';
// import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import UserHeader from './UserHeader';
import yourImage from '../assets/gradientback.jpeg';

export default function SignUpform() {
  const [memberDetails, setMemberDetails] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const recaptchaVerifierRef = useRef(null);
  const navigate = useNavigate();
  const otpInputRef = useRef(null);
  const [otpLoading, setOtpLoading] = useState(false); // State for Generate OTP button
const [loginLoading, setLoginLoading] = useState(false); // State for Login button

  // Handle sending OTP after checking the phone number in the database
  const sendOtp = async () => {
    setOtpLoading(true);
    setSuccess('');
    setError('');
  
    try {
      const response = await axios.post('https://discountfeast.co.in/newmember/checkPhoneNumber', { phoneNumber });
  
      if (response.status === 200 && response.data.exists) {
        if (!recaptchaVerifierRef.current) {
          recaptchaVerifierRef.current = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'invisible',
              callback: (response) => console.log('reCAPTCHA solved', response),
              'expired-callback': () => console.log('reCAPTCHA expired'),
            },
            auth
          );
        }
  
        const appVerifier = recaptchaVerifierRef.current;

        const fullPhoneNumber = `+91${phoneNumber}`; //Live login
       
        // const fullPhoneNumber = `+1${phoneNumber}`; //Localhost login
       
        const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
        setVerificationId(confirmationResult.verificationId);
        setOtpSent(true);
        setError('');
        setLoading(false);

         // Focus on the OTP input field after OTP is sent
      if (otpInputRef.current) {
        otpInputRef.current.focus();
      }
      } else {
        setError('Phone number not found. Please register.');
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Failed to send OTP. Please try again.');
    } finally {
      setOtpLoading(false);
    }
  };
  
  // Verify OTP
  const verifyOtp = async () => {
    if (!otp || !verificationId) {
      setError('Please enter the OTP.');
      return;
    }
  
    setLoginLoading(true);
  
    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setSuccess('OTP Verified Successfully!');
      setError('');
  
      await verifyPhoneNumberWithBackend();
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP. Please enter the correct OTP.');
    } finally {
      setLoginLoading(false);
    }
  };

  const verifyPhoneNumberWithBackend = async () => {
    try {
      // Verify phone number with backend
      const verifyResponse = await axios.post('https://discountfeast.co.in/newmember/verifylogin', { phoneNumber });
      
      if (verifyResponse.status === 200) {
        setSuccess('Phone number verified successfully!');
        localStorage.setItem('userPhoneNumber', phoneNumber); // Store phone number in local storage
  
        // Fetch user data from backend
        const userResponse = await axios.get(`https://discountfeast.co.in/newmember/all/${phoneNumber}`);
  
        if (userResponse.status === 200) {
          const userData = userResponse.data;
  
          // Optionally store user data in local storage or in the state
          localStorage.setItem('userData', JSON.stringify(userData)); // Save user data to localStorage
          console.log('User Data:', userData); // You can handle user data further in your app
  
          // Navigate to the home or user profile page
          navigate('/');
        } else {
          setError('Failed to fetch user data.');
        }
      } else {
        setError('Phone number not verified or not found.');
      }
    } catch (err) {
      setError('Error verifying phone number: ' + (err.response?.data?.message || err.message));
    }
  };
  

  useEffect(() => {
    if (phoneNumber.length === 10 && !otpSent) {
      sendOtp();
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (otp.length === 6 && verificationId) {
      verifyOtp();
    }
  }, [otp]);

    // const navigate = useNavigate();
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, navigate to the dashboard
          navigate('/'); // Adjust the route as needed
        } else {
          // No user is signed in, navigate to the login page
          navigate('/signupform');
        }
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate]);

  return (
    <>
    <div
  style={{
    backgroundImage: `url(${yourImage})`,
    backgroundSize: 'cover',      // Makes the image cover the entire div
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundPosition: 'center',  // Centers the image within the div
    // height: '500px',               // Adjust the height as needed
  }}
>
      <Col lg={12}>
      <UserHeader/>
      </Col> 
      <Container fluid className=" p-0 " style={{width:'95%'}}>
        
        <Row className="w-100" style={{ marginLeft: '0px' }}>

        <Col lg={12} md={10} sm={12} xs={12} className="pt-4 ">
    <div
      className="shadow-container"
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
      }}
    >

<Row className="w-100">
          <Col lg={6} md={12} sm={12} xs={12} className="form1 d-flex   pt-1">
            <div className="content-wrapper w-100">
              <div className="signinfrm form-container mx-auto" style={{ padding: '0 10%' }}>
                <h2  >Member Login,</h2>
                <p>Membership Validity 999 Days.</p>

                <Form className="d-grid ">
                  <Row className="">
                    <Col lg={12} sm={12} md={12} xs={12}>
                      <Form.Group controlId="PhoneNumber" className="mb-3 mt-2">
                        {/* <Form.Label className="">Phone Number</Form.Label> */}
                        <div className="d-flex">
                          <Form.Control
                           className="inputfield border-bottom-only"
                            type="text"
                            placeholder="Enter Phone Number"
                            style={{backgroundColor:'transparent'}}
                            value={phoneNumber}
                            onChange={(e) => {
                              setPhoneNumber(e.target.value);
                              setMemberDetails({ ...memberDetails, phoneNumber: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={6} md={12} sm={12} xs={12} className="d-flex ">
                  <Button
  variant="warning"
  style={{
    width: '100%',
    backgroundColor: '#EF4C0B',
    color: 'white',
    border: 'none',
  }}
  onClick={sendOtp}
  disabled={otpLoading} // Use otpLoading for Generate OTP button
>
  {otpLoading ? 'Sending...' : 'Generate OTP'}
</Button>
                    </Col>
                  </Row>

                  <Row >
                    <Col lg={6} sm={12} md={12} xs={12} >
                      <Form.Group controlId="otp" className="mb-3 mt-3">
                        {/* <Form.Label className="forminput">OTP</Form.Label> */}
                        <Form.Control
                         className="smsotp"
                          style={{textAlign:'center',backgroundColor:'#D9D9D9'}}
                          type="text"
                          placeholder="Enter SMS OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          required
                          ref={otpInputRef}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {error && (
                    <div className="text-center mt-2">
                      <p className="text-danger">{error}</p>
                    </div>
                  )}
                  {success && (
                    <div className="text-center mt-2">
                      <p className="text-success">{success}</p>
                    </div>
                  )}

                  <Row  className="" style={{ padding: '0 ' }}>
                    <Col lg={12} md={12} sm={12} xs={12} className="d-flex ">
                    <Button
  variant="warning"
  style={{
    width: '100%',
    backgroundColor: '#EF4C0B',
    color: 'white',
    border: 'none',
    height: '50px',
  }}
  onClick={verifyOtp}
  disabled={loginLoading} // Use loginLoading for Login button
>
  {loginLoading ? 'Processing...' : 'Login'}
</Button>
                    </Col>
                  </Row>

                  <Row>
                    
                    <Col lg={12} md={12} sm={12} xs={12} className=" mt-2">
                    Not a member yet?<Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '0px 0px',
                borderRadius: 'none',
                color:'#0040FF',
                backgroundColor:'transparent',
                border:'none'
                
              }}
              onClick={() => navigate('/requestmembership')}
            >
             Become a member
            </Button>
                      
                    </Col>
                  </Row>

                  <Col lg={12} className="mt-2 text-center">
                    <small>
                      {/* This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply. */}
                    </small>
                    <div id="recaptcha-container"></div>
                  </Col>
                </Form>
              </div>
            </div>
          </Col>

          <Col lg={6} md={12} sm={12} xs={12} className="form1    pt-1">
          
        <h3 style={{fontWeight:'bold',paddingLeft:'20px'}}>How It Works:</h3>

        <ol  className="pt-3 custom-list">
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Log In to Your Account</h6>
    <p>Use your phone number and OTP to log in.</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Browse and Find Deals</h6>
    <p>Search for hotels and deals near you that catch your eye.</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Redeem Offers</h6>
    <p>Use the deal you love within 24 hours at the hotel or store.</p>
  </li>
  <li>
    <h6 style={{ fontWeight: 'bold' }}>Enjoy with Discount Feast!</h6>
    <p>Experience more savings and fun with every purchase.</p>
  </li>
</ol>

         </Col>
         </Row>
         </div>
         </Col>
        </Row>
      </Container>
      <Col lg={12} className='mt-4'>
      <Footer/>
      </Col>
     
      </div>
    </>
  );
}
