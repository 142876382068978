
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import { auth } from './firebase.config'; // Adjust the path accordingly
import Coupon1 from './Components/Coupon1';
import Coupon2 from './Components/Coupon2';
import Pay from './Components/Pay';
import Offercards from './Components/Offercards';
import SignUp from './Components/SignUp';
import SignUpform from './Components/SignupFrm';
import RequestForMembership from './Components/RequestForMembership';
import Header from './Components/Header';
import MyCards from './Components/MyCards'
import UpdateProfile from './Components/UpdateProfile'
// import MyProfile from './Components/MyProfile'
import ProtectedRoute from './Components/ProtectedRoute'// The protected route component
import TermCondition from './Components/TermCondition';
import AboutUs from './Components/AboutUs';
import PrivacyPolicy from './Components/PrivacyPolicy'
import ContactUs from './Components/ContactUs';
import Refundpolicy from './Components/Refundpolicy';
import JoinSalesTeam from './Components/JoinSalesTeam';
export default function App() {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // You can handle user state changes if necessary
    });

    return () => unsubscribe();
  }, []);

  
  return (
    <Router>
      <Routes>
      <Route path='/coupon1' element={<Coupon1/>} />
      <Route path='/coupon2' element={<Coupon2/>} />
      <Route path='/pay' element={<Pay/>} />
      <Route path='/' element={<Offercards/>} />
      <Route path='/signin' element={<SignUp/>} />
      <Route path='/termcondition' element={<TermCondition/>}/>
      <Route path='/signupform' element={<SignUpform/>} />
      <Route path='/requestmembership' element={<RequestForMembership/>} />
      <Route path='/header' element={<Header/>}/>
      <Route path='/mycards' element={<MyCards/>}/>
      <Route path='/updateprofile' element={<UpdateProfile/>}/>
      <Route path='/myprofile' element={<MyCards/>}/>
      <Route path='/termcondition' element={<TermCondition/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
      <Route path='/refundpolicy' element={<Refundpolicy/>}/>
      <Route path='/contactus' element={<ContactUs/>}/>
      <Route path='/joinsalesteam' element={<JoinSalesTeam/>}/>

      
      {/* <Route path='/mycards' element={<RedeemedCouDtl/>}/> */}
      </Routes>
    </Router>
  );
}
