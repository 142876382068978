import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import UserHeader from './UserHeader';
import Footer from './Footer';
import '../CSS/joinsales.css';
import yourImage from '../assets/gradientback.jpeg';
import { IoMdArrowDropdown } from "react-icons/io";

const SalesPartnerForm = () => (
    <>
        <div
            style={{
                backgroundImage: `url(${yourImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                minHeight: '100vh',
                display: 'flex', // Add flexbox
                justifyContent: 'center', // Horizontally center the content
                // alignItems: 'center', // Vertically center the content
                flexDirection: 'column', // Stack elements vertically
                overflowY: 'auto',
            }}
        >
            <Col lg={12}>
                <UserHeader />
            </Col>
            <Container
                fluid
                className="p-0 mb-3"
                style={{
                    width: '100%',
                    maxWidth: '831px', // Control max width for better responsiveness
                    margin: '0 auto', // Ensures it stays centered on all screen sizes
                    
                }}
            >
                <Row className="w-100" style={{ marginLeft: '0px' }}>
                    <Col lg={12} md={12} sm={12} xs={12} className="p-3">
                        <div
                            className="shadow-container"
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '15px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                padding: '15px',
                                paddingLeft:'35px'
                            }}
                        >
                            <Row className="w-100 p-2">
                                {/* Left Section: Form */}
                                <Col md={12} lg={12}>
                                    <Container>
                                        <h2 className="joinsale" >Become a Discount Feast  Sales Partner</h2>
                                        <p className="text-start">
                                            Please fill out this form to register as a channel sales partner with Discount Feast. For any queries, you may reach out over WhatsApp at 8421029663 or email at: onboarding@discountfeast.co.in
                                        </p>

                                        <Form className="formdecor">
                                            {/* <Row className="mb-3">
                                                <Col>
                                                    <Form.Group controlId="email">
                                                        <Form.Label className="lableclass ">Email:</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            // placeholder="Enter your email"
                                                            required
                                                            className="inputfield1 custom-dropdown" />
                                                    </Form.Group>
                                                </Col>
                                            </Row> */}
                                            <Row className="mb-3">
                                                <Col style={{ position: 'relative' }}>
                                                    <Form.Group controlId="category">
                                                        <Form.Label className="lableclass">
                                                            Which Category of Sales Partner would you like to become?
                                                        </Form.Label>
                                                        <div style={{ position: 'relative' }}>
                                                            <Form.Select
                                                                defaultValue=""
                                                                className="custom-dropdown"
                                                                style={{
                                                                    paddingRight: '40px', // Add space for the icon
                                                                    appearance: 'none', // Hide the native dropdown arrow
                                                                }}
                                                            >
                                                                <option value="" disabled>
                                                                    Choose
                                                                </option>
                                                                <option value="option1">Bronze - No Upfront Investment. Buys less than 100 Cards. Commission Rs. 500/-per Card.</option>
                                                                <option value="option2">Silver-Upfront Investment for only up-to 100 Cards. Commission Rs.650/- per Card</option>
                                                                <option value="option3">Gold-Am a Sales GOD. Investment for 1000 Cards and Above. Commission Rs.850/- per card</option>
                                                                <option value="option4">Platinum - Am the GOD of Sales GODS-Investment of 5000 Cards and Above. Commission Rs.1111/- Per Card</option>
                                                            </Form.Select>
                                                            {/* Dropdown Icon */}
                                                            <IoMdArrowDropdown
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    right: '10px',
                                                                    transform: 'translateY(-50%)',
                                                                    pointerEvents: 'none', // Ensure the icon doesn't block interaction
                                                                    color: '#EF4C0B', // Adjust color to fit design
                                                                    fontSize: '1.5rem',
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Group controlId="workProfile">
                                                        <Form.Label className="lableclass">Current Work Profile:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            // placeholder="Enter your current work profile"
                                                            required
                                                            className="inputfield1 custom-dropdown" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col style={{ position: 'relative' }}>
                                                    <Form.Group controlId="timeline">
                                                        <Form.Label className="lableclass">How Soon can you start:</Form.Label>
                                                        <div style={{ position: 'relative' }}>
                                                            <Form.Select
                                                                defaultValue=""
                                                                className="custom-dropdown"
                                                                style={{
                                                                    paddingRight: '40px', // Add space for the icon
                                                                    appearance: 'none', // Hide default arrow
                                                                }}
                                                            >
                                                                <option value="" disabled>
                                                                    Mention the timeline under which you can start
                                                                </option>
                                                                <option value="1 Week">1 Week</option>
                                                                <option value="2 Weeks">2 Weeks</option>
                                                                <option value="1 Months">1 Months</option>
                                                                <option value="More Than 1 Month">More Than 1 Month</option>
                                                            </Form.Select>
                                                            {/* Dropdown Icon */}
                                                            <IoMdArrowDropdown
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    right: '10px',
                                                                    transform: 'translateY(-50%)',
                                                                    pointerEvents: 'none', // Ensure the icon doesn't block interaction
                                                                    color: '#EF4C0B', // Adjust color to match the design
                                                                    fontSize: '1.5rem',
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h5 className="lableclass">Contact Info</h5>
                                                    <p className="lableclasss">Please share your contact details so our team can reach out to you.</p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Group controlId="name">
                                                        <Form.Label className="lableclass">Your Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            // placeholder="Enter your name"
                                                            required
                                                            className="inputfield1 custom-dropdown" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Group controlId="email">
                                                        <Form.Label className="lableclass">Email:</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            // placeholder=""
                                                            required
                                                            className="inputfield1 custom-dropdown" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    {/* <Form.Group>
                                                    <Form.Label className="lableclass">Preferred Contact Method:</Form.Label>
                                                    <div className="boxcheck">
                                                        <div>
                                                            <Form.Check
                                                                inline
                                                                label={<span>Phone</span>}
                                                                type="checkbox"
                                                                id="contactPhone"
                                                                value="phone"
                                                            />
                                                        </div>
                                                        <div>
                                                            <Form.Check
                                                                inline
                                                                label={<span>Email</span>}
                                                                type="checkbox"
                                                                id="contactEmailCheckbox"
                                                                value="email"
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group> */}
                                                    <Form.Group>
                                                        <Form.Label
                                                            className="lableclass"
                                                            style={{
                                                                color: '#EF4C0B',
                                                                fontWeight: 'bold',
                                                                marginBottom: '10px',
                                                                display: 'block',
                                                            }}
                                                        >
                                                            Preferred Contact Method:
                                                        </Form.Label>
                                                        <div className="boxcheck"
                                                            style={{
                                                                border: '1px solid  #333',
                                                                // padding: '10px',
                                                                borderRadius: '5px',
                                                                display: 'flex',
                                                                flexDirection: 'column', // Stack checkboxes vertically
                                                                // gap: '8px', // Add space between checkboxes
                                                            }}
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="contactPhone"
                                                                value="phone"
                                                                label="Phone"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '8px', // Add space between checkbox and label
                                                                }}
                                                                labelStyle={{
                                                                    marginLeft: '10px', // Additional spacing for label
                                                                }}
                                                                className="custom-checkbox"
                                                            />
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="contactEmail"
                                                                value="email"
                                                                label="Email"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '8px', // Add space between checkbox and label
                                                                }}
                                                                labelStyle={{
                                                                    marginLeft: '10px', // Additional spacing for label
                                                                }}
                                                                className="custom-checkbox "
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Group controlId="comments">
                                                        <Form.Label className="lableclass">Questions and Comments:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            // placeholder="Enter any questions or comments"
                                                            className="inputfield1 custom-dropdown" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col className="text-center">
                                                    <Button
                                                        type="submit"
                                                        // variant="primary"
                                                        // style={{
                                                        //     width:'50%',
                                                        //     height:'50px',
                                                        //     backgroundColor: '#EF4C0B',
                                                        //     border: 'none',
                                                        //     // padding: '10px 20px',
                                                        //     fontSize: '16px',
                                                        //     borderRadius: '5px',
                                                        // }}
                                                        className="submitbtn"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Container>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

        <div>
            <Footer />
        </div>

    </>
);

export default SalesPartnerForm;


// import React from "react";
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import UserHeader from './UserHeader';
// import Footer from './Footer';
// import '../CSS/joinsales.css';
// import yourImage from '../assets/gradientback.jpeg';
// import { IoMdArrowDropdown } from "react-icons/io";

// const SalesPartnerForm = () => (
//     <>
//         <div
//             style={{
//                 backgroundImage: `url(${yourImage})`,
//                 backgroundSize: 'cover',
//                 backgroundRepeat: 'no-repeat',
//                 backgroundPosition: 'center',
//                 minHeight: '100vh',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 overflowY: 'auto',
//             }}
//         >
//             <Col lg={12}>
//                 <UserHeader />
//             </Col>
//             <Container
//                 fluid
//                 className="p-0 mb-3"
//                 style={{
//                     width: '100%',
//                     maxWidth: '800px',
//                     margin: '0 auto',
//                 }}
//             >
//                 <Row className="w-100 justify-content-center m-0">
//                     <Col lg={6} md={8} sm={10} xs={12} className="p-3">
//                         <div
//                             className="shadow-container"
//                             style={{
//                                 backgroundColor: '#FFFFFF',
//                                 borderRadius: '15px',
//                                 boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                                 padding: '15px',
//                             }}
//                         >
//                             <Row className="w-100 p-2">
//                                 <Col>
//                                     <h1 className="text-start">Become a Discount Feast <br /> Sales Partner</h1>
//                                     <p className="text-start">
//                                         Please fill out this form to register as a channel sales partner with Discount Feast. For any queries, you may reach out over WhatsApp at{" "}
//                                         <strong>8421029663</strong> or email at:{" "}
//                                         <a href="mailto:onboarding@discountfeast.co.in">
//                                             onboarding@discountfeast.co.in
//                                         </a>
//                                     </p>
//                                     <Form className="formdecor">
//                                         {/* Form Fields */}
//                                         <Row className="mb-3">
//                                             <Col>
//                                                 <Form.Group controlId="email">
//                                                     <Form.Label className="lableclass">Email:</Form.Label>
//                                                     <Form.Control
//                                                         type="email"
//                                                         required
//                                                         className="inputfield1 custom-dropdown" />
//                                                 </Form.Group>
//                                             </Col>
//                                         </Row>
//                                         <Row className="mb-3">
//                                             <Col>
//                                                 <Form.Group controlId="category">
//                                                     <Form.Label className="lableclass">
//                                                         Which Category of Sales Partner would you like to become?
//                                                     </Form.Label>
//                                                     <div style={{ position: 'relative' }}>
//                                                         <Form.Select
//                                                             defaultValue=""
//                                                             className="custom-dropdown"
//                                                             style={{
//                                                                 paddingRight: '40px',
//                                                                 appearance: 'none',
//                                                             }}
//                                                         >
//                                                             <option value="" disabled>
//                                                                 Choose
//                                                             </option>
//                                                         </Form.Select>
//                                                         <IoMdArrowDropdown
//                                                             style={{
//                                                                 position: 'absolute',
//                                                                 top: '50%',
//                                                                 right: '10px',
//                                                                 transform: 'translateY(-50%)',
//                                                                 pointerEvents: 'none',
//                                                                 color: '#EF4C0B',
//                                                                 fontSize: '1.5rem',
//                                                             }}
//                                                         />
//                                                     </div>
//                                                 </Form.Group>
//                                             </Col>
//                                         </Row>
//                                         <Row className="mb-3">
//                                             <Col>
//                                                 <Form.Group controlId="workProfile">
//                                                     <Form.Label className="lableclass">Current Work Profile:</Form.Label>
//                                                     <Form.Control
//                                                         type="text"
//                                                         required
//                                                         className="inputfield1 custom-dropdown" />
//                                                 </Form.Group>
//                                             </Col>
//                                         </Row>
//                                         <Row className="mb-3">
//                                             <Col>
//                                                 <Form.Group controlId="timeline">
//                                                     <Form.Label className="lableclass">How Soon can you start:</Form.Label>
//                                                     <div style={{ position: 'relative' }}>
//                                                         <Form.Select
//                                                             defaultValue=""
//                                                             className="custom-dropdown"
//                                                             style={{
//                                                                 paddingRight: '40px',
//                                                                 appearance: 'none',
//                                                             }}
//                                                         >
//                                                             <option value="" disabled>
//                                                                 Mention the timeline under which you can start
//                                                             </option>
//                                                         </Form.Select>
//                                                         <IoMdArrowDropdown
//                                                             style={{
//                                                                 position: 'absolute',
//                                                                 top: '50%',
//                                                                 right: '10px',
//                                                                 transform: 'translateY(-50%)',
//                                                                 pointerEvents: 'none',
//                                                                 color: '#EF4C0B',
//                                                                 fontSize: '1.5rem',
//                                                             }}
//                                                         />
//                                                     </div>
//                                                 </Form.Group>
//                                             </Col>
//                                         </Row>
//                                         <Row>
//                                             <Col>
//                                                 <h3 className="lableclass">Contact Info</h3>
//                                                 <p>Please share your contact details so our team can reach out to you.</p>
//                                             </Col>
//                                         </Row>
//                                         {/* Other fields */}
//                                     </Form>
//                                 </Col>
//                             </Row>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//         <div>
//             <Footer />
//         </div>
//     </>
// );

// export default SalesPartnerForm;
