import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import UserHeader from './UserHeader';
import Footer from './Footer';
import { FaArrowLeft } from 'react-icons/fa'; // Importing back arrow icon

export default function Refundpolicy() {
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, navigate to the dashboard
                navigate('/refundpolicy'); // Adjust the route as needed
            } else {
                // No user is signed in, navigate to the login page
                navigate('/refundpolicy');
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);

    // Inline styles for lists
    const mainListStyle = {
        listStyleType: 'decimal',
        paddingLeft: '20px',
    };


    return (
        <>
            <UserHeader />

            <Container className="mt-2  " style={{minHeight:'50vh'}}>
                {/* Back Arrow Button */}
                <button
                    className="btn btn-link mb-3 justify-content-center"
                    onClick={() => navigate('/')} // Go back to the previous page
                    style={{ fontSize: '20px', color: 'black', textDecoration: 'none', display: 'flex' }}
                >
                    <FaArrowLeft />
                </button>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <Card className="p-3" style={{ border: 'none' }}>
                            <Card.Body>
                                <h2 className="text-center mb-5">Refund Policy</h2>
                                <div className="terms-container" >
                                    <p className='text-center'> <b>We are not Providing any kind of Refund policy</b></p>
                                    {/* <p>
                                    This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service that you have purchased through the Platform. Under this policy:
                                    </p>
                                    <ol style={mainListStyle}>
                                        <li>
                                    	Cancellations will only be considered if the request is made 6 days of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to such sellers / merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
                                        </li>
                                        <li>
                                        <b>	DISCOUNT FEAST </b>does not accept cancellation requests for perishable items like flowers, eatables, etc. However, the refund / replacement can be made if the user establishes that the quality of the product delivered is not good.
                                        </li>
                                        <li>
                                        	In case of receipt of damaged or defective items, please report to our customer service team. The request would be entertained once the seller/ merchant listed on the Platform, has checked and determined the same at its own end. This should be reported within 6 days of receipt of products. In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 6 days of receiving the product. The customer service team after looking into your complaint will take an appropriate decision.
                                        </li>
                                        <li>
                                        	In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue to them.</li>
                                        <li>
                                        	In case of any refunds approved by <b> DISCOUNT FEAST </b>, it will take <b> 7 days</b> for the refund to be processed to you.
                                        </li>
                                    </ol> */}

                                </div>



                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Footer />

        </>

    )
}
