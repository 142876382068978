// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import logoimage from '../assets/DF-LOGO1 (2).png'; // Adjust the path as needed
// import { FaTwitter ,FaInstagram } from 'react-icons/fa';
// import { FaLinkedin } from "react-icons/fa";
// import { useNavigate } from 'react-router-dom';
// import { FaFacebookF } from "react-icons/fa";
// import { RiLinkedinFill } from "react-icons/ri";
// // import { FaTwitter } from "react-icons/fa";

// const Footer = () => {
//   const navigate = useNavigate(); // Initialize navigate function

 
//   return (
//     <footer className="  text-black py-4" style={{backgroundColor:'#D9D9D9'}}>
//       <Container>
//         <Row className="justify-content-center align-items-center text-start">
//           {/* Logo and About Us Section */}
//           <Col lg={6} xs={12} md={6} className="mb-4 mb-md-0 text-start">
           

//             <div className="mt-3">
//               <h5>About Us</h5>
//               <p style={{ color: 'black', lineHeight: '1.6',textAlign: 'justify' }}>
//               Discount Feast is a membership program committed to provide you a great discount experience in Food and other shopping.
// Unlike other vouchers which you get after shopping and mostly useless in real sense, our membership program offers 
// you a "Discount Deal" whenever "YOU" need it. Unique feature of our program is "No Cap" discounts and hassle-free Online
// Coupons. Whatever discount is committed to you via "ONLINE COUPONS" you will get it on any amount of billing.
//               </p>
//             </div>
//             <div className="d-flex justify-content-start social-icons mt-3">
//               <a href=""   target="_blank" className=" mx-2">
//               <div  style={{width:'25px',height:'25px',borderRadius:'50px',backgroundColor: '#1877F2',color:'white',padding:'3px'}}>
//                 <FaTwitter  size={20} />
//                 </div>
//               </a>
//               <a href="https://www.facebook.com/people/Discount-Feast-India/61566595436502/?mibextid=qi2Omg&rdid=ipnWoMCroGgDTYGT&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FSxruKxXo9yH4FSsS%2F%3Fmibextid%3Dqi2Omg"   target="_blank"  className="text-light mx-2">
//                <div  style={{width:'25px',height:'25px',borderRadius:'50px',backgroundColor: '#1877F2',padding:'2px'}}>
//                 <FaFacebookF size={20} />
//                 </div>
//               </a>

//               <a href="https://www.instagram.com/discount_feast/?igsh=MXAzZjR1dzVocnV2Yw%3D%3D"    target="_blank"  className="text-light mx-2">
//               <div style={{width:'25px',height:'25px',padding:'2px',borderRadius:'50px',background: 'linear-gradient(45deg, #feda75, #f58529, #dd2a7b, #8134af, #515bd4, #3dc1fd)'}}>
             
//                 <FaInstagram size={20}  />
//                 </div>
//               </a>
             
//               <a href="https://www.linkedin.com/company/discount-feast"    target="_blank"  className="text-light mx-2">
//               <div  style={{width:'25px',height:'25px',borderRadius:'50px',backgroundColor: '#1877F2',padding:'2px'}}>
//                 <RiLinkedinFill size={20} />
//                 </div>
//               </a>
//             </div>
//           </Col>

//           {/* Discover, About, and Legal Sections */}
//           <Col lg={6}  xs={12} md={6} className="justify-content-center">
//             <Row className="justify-content-center">
             

//               <Col xs={12} md={4} className="mb-4 mb-md-0 text-start">
//                 <h5>Company</h5>
//                 <ul className="list-unstyled">
//                   <li className="mb-2">
//                     {/* Using onClick instead of href */}
//                     <span
//                      onClick={() => navigate('/aboutus')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                       About Us
//                     </span>
//                   </li>
//                   <li className="mb-2">
//                     {/* Using onClick instead of href */}
//                     <span
//                     //  onClick={() => navigate('/aboutus')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                      Become a sales partner
//                     </span>
//                   </li>

//                   <li className="mb-2">
//                     {/* Using onClick instead of href */}
//                     <span
//                     //  onClick={() => navigate('/aboutus')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                     Register your store/restaurant
//                     </span>
//                   </li>




                 
//                   <li className="mb-2">
//                     <span
//                       onClick={() => navigate('/')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                       Affiliates
//                     </span>
//                   </li>
//                 </ul>
//               </Col>

//               <Col xs={12} md={4} className="mb-4 mb-md-0 text-start">
//                 <h5>Legal</h5>
//                 <ul className="list-unstyled">
//                   <li className="mb-2">
//                     {/* Using onClick instead of href */}
//                     <span
//                      onClick={() => navigate('/contactus')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                       Contact
//                     </span>
//                   </li>
//                   <li className="mb-2">
//                     <span
//                      onClick={() => navigate('/privacypolicy')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                       Privacy Policies
//                     </span>
//                   </li>
//                   <li className="mb-2">
//                     <span
//                       onClick={() => navigate('/termcondition')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                       Terms & Conditions
//                     </span>
//                   </li>

//                   <li className="mb-2">
//                     <span
//                       onClick={() => navigate('/refundpolicy')}
//                       style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
//                     >
//                      Refund and Cancellation policy
//                     </span>
//                   </li>

//                 </ul>
//               </Col>
//             </Row>
//           </Col>
//         </Row>

//         {/* Footer Bottom */}
//         <Row className="mt-4 pt-2 text-center" style={{ borderTop: '2px solid black' }}>
//           <Col>
//             <small style={{ color: "black" }}>&copy;2024 Discount Feast. All Rights Reserved.</small>
//           </Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;


import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";


const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="text-black py-4" style={{ backgroundColor: "#D9D9D9" }}>
      <Container>
        <Row className=" align-items-start">
          {/* Logo and About Us Section */}
          <Col lg={6} md={4} sm={12} xs={12} className="">
          <Col lg={8} md={4} sm={12} xs={12} className="">
            <h5>About Us</h5>
            <p style={{ color: "black", lineHeight: "1.6", textAlign: "justify" }}>
              Discount Feast is a membership program committed to providing you a great discount experience in food and
              other shopping. Unlike other vouchers, our membership program offers you a "Discount Deal" whenever
              *you* need it. Unique features include "No Cap" discounts and hassle-free online coupons.
            </p>
            <div className="d-flex mt-3">
             
              <a
                href="https://www.facebook.com/people/Discount-Feast-India/61566595436502/?mibextid=qi2Omg&rdid=ipnWoMCroGgDTYGT&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FSxruKxXo9yH4FSsS%2F%3Fmibextid%3Dqi2Omg"
                target="_blank"
                className="mx-2"
              >
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    color:'white',
                    backgroundColor: "#1877F2",
                    padding: "2px",
                  }}
                >
                  <FaFacebookF size={20} />
                </div>
              </a>
              <a
                href="https://www.instagram.com/discount_feast/?igsh=MXAzZjR1dzVocnV2Yw%3D%3D"
                target="_blank"
                className="mx-2"
              >
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    color:'white',
                    padding: "3px",
                    background:
                      "linear-gradient(45deg, #feda75, #f58529, #dd2a7b, #8134af, #515bd4, #3dc1fd)",
                  }}
                >
                  <FaInstagram size={20} />
                </div>
              </a>

              <a href="#" target="_blank" className="mx-2">
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    backgroundColor: "#1C1C1B",
                    color: "white",
                    padding: "3px",
                  }}
                >
                  <FaXTwitter size={20} />
                </div>
              </a>

              <a href="https://www.linkedin.com/company/discount-feast" target="_blank" className="mx-2">
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    color:'white',
                    backgroundColor: "#1877F2",
                    padding: "2px",
                  }}
                >
                  <RiLinkedinFill size={20} />
                </div>
              </a>
            </div>
          </Col>
          </Col>

          {/* Company Links Section */}
          <Col lg={3} md={4} sm={12} xs={12} className="mb-4">
            <h5>Company</h5>
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li className="mb-2">
                <span
                  onClick={() => navigate("/aboutus")}
                  style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                >
                  About Us
                </span>
              </li>
              <li className="mb-2">
                <span
                 onClick={() => navigate("/joinsalesteam")}
                 style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
                
                  Become a Sales Partner
                </span>
              </li>
              <li className="mb-2">
                <span style={{ textDecoration: "none", color: "black", cursor: "pointer" }}>
                  Register Your Store/Restaurant
                </span>
              </li>
              <li className="mb-2">
                <span
                  onClick={() => navigate("/")}
                  style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                >
                  Affiliates
                </span>
              </li>
            </ul>
          </Col>

          {/* Legal Links Section */}
          <Col lg={3} md={4} sm={12} xs={12} className="mb-4">
            <h5>Legal</h5>
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li className="mb-2">
                <span
                  onClick={() => navigate("/contactus")}
                  style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                >
                  Contact
                </span>
              </li>
              <li className="mb-2">
                <span
                  onClick={() => navigate("/privacypolicy")}
                  style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                >
                  Privacy Policies
                </span>
              </li>
              <li className="mb-2">
                <span
                  onClick={() => navigate("/termcondition")}
                  style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                >
                  Terms & Conditions
                </span>
              </li>
              <li className="mb-2">
                <span
                  onClick={() => navigate("/refundpolicy")}
                  style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
                >
                 Refund Policy
                </span>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Footer Bottom */}
        <Row className="text-center mt-3" style={{ borderTop: "3px solid black" }}>
          <Col>
            {/* <small style={{ color: "black" }}>&copy; 2024 Discount Feast. All Rights Reserved.</small> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
